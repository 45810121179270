import React, { Component } from 'react';

class Slide extends Component {
    render() {
        return (
            <div>
                <h3>{this.props.header}</h3>
                <div>{this.props.children}</div>
            </div>
        );
    }
}

export default Slide;
