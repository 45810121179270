import React, { Component } from 'react';
import axios from 'axios';
import Slider from "react-slick";
import Slide from "./Slide";
import './App.css';
import logo from './logo.svg';

class App extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.subscribeNewsletter = this.subscribeNewsletter.bind(this);
        this.state = {
            index: 0,
            firstName: '',
            lastName: '',
            email: ''
        };
    }

    componentDidMount() {
        for (let i = 0; i <= 6; i++) {
            const img = new Image();
            img.src = `${i}.jpg`;
        }
    }

    handleChange(current, next) {
        document.getElementById('content').scrollTop = 100;
        this.setState({index: next});
    }

    next() {
        this.slider.slickNext();
    }

    subscribeNewsletter(e) {
        e.preventDefault();
        const doRequest = axios.post(
            'https://actionnetwork.org/api/v2/forms/ef6d4011-499d-4008-9a7a-2758bd2db67d/submissions',
            {
                person: {
                    family_name: this.state.lastName,
                    given_name: this.state.firstName,
                    email_addresses: [
                        {primary: true, address: this.state.email, status: 'subscribed'}
                    ],
                    postal_addesses: [
                        {primary: true, country: 'NL', postal_code: null}
                    ]
                },
                custom_fields: {
                    Telefoonnummer: null
                },
                triggers: {
                    autoresponse: {enabled: true}
                },
                "action_network:referrer_data": {
                    website: "https://spreekjeuit.groenlinks.nl",
                    referrer: ''
                }
            }
        );
        doRequest.then(() => { this.setState({firstName: '', lastName: '', email: ''}) });
        this.slider.slickNext();
    }

    render() {
      const settings = {
          adaptiveHeight: true,
          beforeChange: this.handleChange.bind(this),
          dots: true,
          draggable: false,
          infinite: false,
          speed: 100,
          slidesToShow: 1,
          slidesToScroll: 1
      };

      const oneDay = 1000*60*60*24;
      const date1 = new Date().getTime();
      const date2 = new Date(2019, 4, 23).getTime();
      const countdown = Math.ceil((date2 - date1)/oneDay);

      return (
      <div className="App">
          <div className="App-logo">
              <a href="https://groenlinks.nl" target="_blank"><img src={logo} alt="logo" /></a>
          </div>
          <div id="content" className="App-background" style={{backgroundImage: `url(${this.state.index}.jpg)`}}>
              {(countdown >= 0) && <div className="countdown"><h3>{countdown === 0 ? 'Waar wacht je nog op? Ga stemmen!' : `Nog ${countdown} dag${countdown === 1 ? '' : 'en'} tot aan de Europese Parlementsverkiezingen!`}</h3></div>}
              <div className="App-content">
                  <Slider ref={slider => (this.slider = slider)} {...settings}>
                    <Slide header={"SPREEK JE UIT!"}>
                        <p>
                            Op donderdag 23 mei gaan we naar de stembus voor de Europese verkiezingen. En het lijkt een nek-aan-nekrace te worden tussen GroenLinks, VVD en FvD. Juist nu is het van groot belang dat we een progressief en links geluid laten horen. In Nederland, én in Europa.
                        </p>
                        <p>
                            Elke stem telt. Vorige Europese verkiezingen ging maar 33% van de Nederlanders stemmen. Dat gaan we veranderen met jouw hulp. Hoe? Klik op start, dan laten wij zien wat je kunt doen!
                        </p>
                        <div className="btn-wrapper">
                            <button className="btn-continue" onClick={this.next}>Start 💪</button>
                        </div>
                    </Slide>
                    <Slide header={"SCHRIJF JE IN VOOR ONZE MAILS"}>
                        <p>
                            Met onze mails bereiken we tienduizenden mensen op een rechtstreekse manier: in hun mailbox. Meld je aan voor onze campagnemails en blijf op de hoogte van onze campagne.
                        </p>
                        <h4>Schrijf je in!</h4>
                        <form onSubmit={this.subscribeNewsletter}>
                            <div>
                                <label htmlFor="input-firstname">Voornaam*</label>
                                <input id={"input-firstname"} value={this.state.firstName} onChange={(e) => {this.setState({firstName: e.target.value})}} required/>
                            </div>
                            <div>
                                <label htmlFor="input-lastname">Achternaam*</label>
                                <input id={"input-lastname"} value={this.state.lastName} onChange={(e) => {this.setState({lastName: e.target.value})}} required/>
                            </div>
                            <div>
                                <label htmlFor="input-email">Email*</label>
                                <input id={"input-email"} value={this.state.email} onChange={(e) => {this.setState({email: e.target.value})}} required/>
                            </div>
                            <div className="btn-wrapper">
                                <button className="btn-continue" type={"submit"}>Schrijf je in! 📩</button>
                                <button className="btn-skip" onClick={this.next} type={"button"}>→ Wat nog meer?</button>
                            </div>
                        </form>
                    </Slide>
                    <Slide header={"WORD APPTIVIST"}>
                        <p>
                            Meld je via WhatsApp aan als Apptivist en help ons zoveel mogelijk mensen te bereiken online. Zo krijg je appjes met nieuws, maar schakelen we ook af en toe jouw hulp in met de vraag een bericht op Facebook, Twitter of Instagram te delen. Zonder jou minder bereik!
                        </p>
                        <h4>Hoe?</h4>
                        <ul>
                            <li>Klik op onderstaande link om WhatsApp te openen</li>
                            <li>Er laadt nu automatisch een appje met daarin <em>verandering aan</em></li>
                            <li>Verstuur het appje naar het Apptivisten-nummer</li>
                            <li>Voila; je staat op de lijst en mag jezelf vanaf dit moment Apptivist noemen!</li>
                        </ul>
                        <div className="btn-wrapper">
                            <a className="btn-continue" target={"_blank"} href={"https://wa.me/31657525250?text=verandering+aan"} onClick={this.next}>Word Apptivist 📲</a>
                            <button className="btn-skip" onClick={this.next}>→ Wat nog meer?</button>
                        </div>
                    </Slide>
                    <Slide header={"KOM NAAR EEN EVENEMENT"}>
                        <p>
                            De makkelijkste manier om offline in actie te komen? Kom naar een evenement! Zoals naar de klimaatcolleges van Bas Eickhout, onze lijsttrekker (en klimaatwetenschapper).
                        </p>
                        <p>
                            Wat is de laatste stand van de wetenschap? Welke oplossingen zijn er op klimaatverandering aan te pakken? En welke rol speelt Europa daarin? Waarom worden er zo vaak – onterechte – excuses gemaakt om niks te doen? Alles komt aan bod. Bas vertelt over zijn ontmoetingen met Al Gore (beetje saai) en Greta Thunberg (inspirerend) en over het magische moment toen in 2015 in Parijs het wereldwijde klimaatakkoord werd afgehamerd.
                        </p>
                        <h4>DATA</h4>
                        <ul>
                            <li><a href="https://beweging.groenlinks.nl/klimaatcollege/maastricht">Dinsdag 7 mei: KLIMAATCOLLEGE BAS, Maastricht</a></li>
                            <li><a href="https://beweging.groenlinks.nl/klimaatcollege/middelburg">Woensdag 15 mei: KLIMAATCOLLEGE BAS, Middelburg</a></li>
                            <li><a href="https://beweging.groenlinks.nl/klimaatcollege/groningen">Vrijdag 17 mei: KLIMAATCOLLEGE BAS, Groningen</a></li>
                        </ul>
                        <div className="btn-wrapper">
                            <a className="btn-continue" target={"_blank"} href={"https://beweging.groenlinks.nl/klimaatcollege"} onClick={this.next}>MELD JE AAN 🙌</a>
                            <button className="btn-skip" onClick={this.next}>→ Wat nog meer?</button>
                        </div>
                    </Slide>
                    <Slide header={"STEUN ONZE BEWEGING"}>
                        <p>
                            Geen tijd? Doneer! Met jouw donatie kunnen we nog meer mensen bereiken met onze boodschap en betrekken bij onze beweging. Zo kunnen we online de beste campagnes neerzetten, en onze gratis Meetups organiseren.
                        </p>
                        <div className="btn-wrapper">
                            <a className="btn-continue" target={"_blank"} href={"https://steun.groenlinks.nl"} onClick={this.next}>Steun GroenLinks 💸</a>
                            <button className="btn-skip" onClick={this.next}>→ Wat nog meer?</button>
                        </div>
                    </Slide>
                    <Slide header={"WORD LID"}>
                        <p>
                            We hebben bijna 30.000 leden en zijn daarmee de grootste progressieve ledenpartij op links. Wat het betekent om lid te worden?
                        </p>
                        <ul>
                            <li>Je steunt onze partij al vanaf €2 per maand (gemiddeld geven leden €7 per maand)</li>
                            <li>Je steunt ons daarmee dubbel: we krijgen extra subsidie voor elk lid</li>
                            <li>Je maakt GroenLinks sterker</li>
                            <li>Je kan invloed uitoefenen op onze koers, zoals stemmen bij de vaststelling van de kandidatenlijsten</li>
                            <li>Je kunt het lidmaatschap zo actief als je wil invullen</li>
                        </ul>
                        <div className="btn-wrapper">
                            <a className="btn-continue" target={"_blank"} href={"https://wordlid.groenlinks.nl/"} onClick={this.next}>Word lid ✊</a>
                            <button className="btn-skip" onClick={this.next}>→ Wat nog meer?</button>
                        </div>
                    </Slide>
                    <Slide header={"WORD VRIJWILLIGER"}>
                        <em>En ga mee langs de deuren</em>
                        <p>
                            Samen met onze vrijwilligers gaan we langs de deuren met de vraag: wat speelt er in jouw wijk? Heel belangrijk, want het is niet alleen goed voor de stemopkomst, maar ook blijkt dat mensen meer geneigd zijn GroenLinks te stemmen wanneer we met hen aan de deur in gesprek zijn gegaan. Daarnaast is het voor onze lokale afdelingen heel waardevol om te horen wat er speelt.
                        </p>
                        <div className="btn-wrapper">
                            <a className="btn-continue" target={"_blank"} href={"https://beweging.groenlinks.nl/doe-mee"} onClick={this.next}>Doe mee 🤝</a>
                            <button className="btn-skip" onClick={this.next}>→ Wat nog meer?</button>
                        </div>
                    </Slide>
                    <Slide header={"En, natuurlijk: Stem!"}>
                        <p>
                            Wist je dat het maar <strong>716</strong> stemmen scheelde of GroenLinks was de grootste partij geworden in Noord-Holland bij de Provinciale Statenverkiezingen? En dat we bij de gemeenteraadsverkiezingen in Eindhoven nét tweede zijn geworden, met maar <strong>252</strong> stemmen verschil? In Maastricht scheelde het <strong>560</strong> stemmen. Als grootste partij mag je de eerste zet doen in het vormen van een coalitie, en dat kan veel uitmaken in hoe het bestuur eruit komt te zien.
                        </p>
                        <p>
                            Zo zie je maar: elke stem telt.
                        </p>
                        <p>
                            Die van jou. En die van je vrienden, familie, kennissen en collega’s. Schroom dus niet en vertel zoveel mogelijk mensen dat er op <strong>donderdag 23 mei</strong> weer verkiezingen zijn!
                        </p>
                        <div className="btn-wrapper">
                            <a className="btn-continue" target={"_blank"} href={"https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fspreekjeuit.groenlinks.nl%2F&amp;src=sdkpreparse"}>Deel op Facebook</a>
                            <a className="btn-continue" target={"_blank"} href={"https://twitter.com/home?status=Kijk%20wat%20jij%20kan%20doen%20om%20een%20progressief,%20links%20geluid%20te%20laten%20horen!%20https%3A//spreekjeuit.groenlinks.nl"}>Deel op Twitter</a>
                        </div>
                    </Slide>
                </Slider>
              </div>
            </div>
          </div>
        );
  }
}

export default App;
